.container{
  text-align: center;
}

.dt-title {
  font-size: 40px;
  font-family: 'Righteous', cursive;
  color: azure;
  letter-spacing: .25rem;
}

.dt-subtitle{
  font-size: 20px;
  color:azure;
}

.title-box{
  background-color: #101010;
  border: 1px solid azure;
  border-radius: 1%;
  padding: 5rem 3rem;
}

.bio p{
  padding: 10rem 0 8rem;
  font-size: 1.5rem;
}
